.report-container {
	width: 1800px;
	box-sizing: border-box;
	height: 350px;
	padding: 18px 40px 32px;
	border-radius: 8px;
	background: #ffffff;
	box-shadow: 0 2px 4px 0 #3965981f;
	margin-top: 11px;

	.report-header {
		height: 54px;
		color: #333333;
 		font-size: 18px;
 		font-weight: 500;
 		font-family: "PingFang SC";
	}
	.report-content {
		display: flex;
		justify-content: space-between;
		align-items: start;
	}
}