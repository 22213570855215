.top-nav-container {
	display: flex;
	align-items: center;
	width: 1200px;
	position: relative;
	> div:first-child {
		img {
			width: 46px;
			height: 32px;
		}
	}
	:global {
		.ac-menu {
			margin-left: 100px;
			width: 300px;
			background: #222631;
			.ac-menu-item {
				background: transparent;
				color: #ffffff67;
				font-size: 14px;
				height: 48px;
				line-height: 48px;
			}
			.ac-menu-item-selected {
				color: #ffffff;
				background: linear-gradient(180deg, #00000000 0%, #00000000 60%, #4e5893 100%) !important;
			}
			.ac-menu-item-selected::after {
				border-radius: 25px 25px 0 0;
				border-bottom-width: 4px;
				border-bottom-color: #7489ff;
			}
			.ac-menu-item-active {
				color: #ffffff !important;
			}
			.ac-menu-item-active::after {
				border-radius: 25px 25px 0 0 !important;
				border-bottom-width: 4px !important;
				border-bottom-color: #7489ff !important;
			}
		}
	}
}