.userInfo {
	width: 32px;
	height: 32px;
	position: absolute;
	top: 8px;
	right: 61px;

	.user {
		width: 32px;
		height: 32px;
		background: url("src/shared/assets/png/user.png") no-repeat;
		background-size: cover;
	}
}

.popover {

	:global {
		.ac-popover-content {
			margin-top: 12px;
		}

		.ac-popover-inner {
			width: 223px;
			height: 216px;
			padding: 16px;
			border-radius: 8px;
			opacity: 1;
			border: 1px solid #455cdc;
			background: #222631;
		}
	}
}


.userName {

	height: 16px;
	padding-bottom: 8px;
	border-bottom: 1px #ffffff1a solid;

	.userName_icon {
		width: 12px;
		height: 12px;
		margin-top: 2px;
		background: url("src/shared/assets/png/userName.png") no-repeat;
		background-size: cover;
		float: left;
	}

	.userName_text {
		width: 148px;
		height: 16px;
		margin-left: 2px;
		opacity: 1;
		color: #ffffff;
		line-height: 16px;
		font-size: 12px;
		font-weight: 500;
		font-family: "PingFang SC";
		text-align: left;
		float: left;
	}
}

.balance_title {
	height: 22px;
	margin-top: 8px;
	margin-bottom: 4px;
	opacity: 1;
	color: #ffffff;
	font-weight: 400;
	font-family: "PingFang SC";
	text-align: left;

	& > div:nth-of-type(1) {
		float: left;
		font-size: 14px;
	}

	& > div:nth-of-type(2) {
		height: 12px;
		margin-top: 2px;
		float: left;
		font-size: 12px;
	}

	& > div:nth-of-type(3) {
		float: left;
		font-size: 14px;
	}
}

.balance_number {
	width: 100%;
	height: 18px;
	margin-bottom: 13px;
	opacity: 1;
	color: #ffffff;
	line-height: 18px;
	font-size: 18px;
	font-weight: 400;
	font-family: "Josefin Sans";
	text-align: left;
}

.balance_manage {
	width: 100%;
	height: 38px;

	:global {
		.ac-btn {
			padding: 0;
		}

		.ac-btn:hover {
			color: #ffffff !important;
			border: 1px solid #455cdc !important;
			background: #607cdc;
			opacity: 1;
			border: none;
		}
	}

	.deposit {
		width: 91px;
		height: 34px;
		border-radius: 8px;
		opacity: 1;
		border: 1px solid #455cdc;
		background: #455cdc;
		color: #ffffff;
		font-size: 12px;
		line-height: 34px;
		text-align: center;
	}

	.withdraw {
		width: 91px;
		height: 34px;
		margin-left: 7px;
		border-radius: 8px;
		opacity: 1;
		border: 1px solid #455cdc;
		background: #455cdc1a !important;
		color: #455CDC;
		font-size: 12px;
		line-height: 34px;
		text-align: center;
	}
}

.account {
	height: 12px;
	margin-top: 8px;
	opacity: 1;
	color: #ffffff;
	font-size: 12px;
	font-weight: 400;
	font-family: "PingFang SC";
	text-align: left;
	cursor: pointer;
}

.logOut {
	height: 12px;
	margin-top: 12px;
	opacity: 1;
	color: #ffffff;
	font-size: 12px;
	font-weight: 400;
	font-family: "PingFang SC";
	text-align: left;
	cursor: pointer;
}