.layout-container {
	box-sizing: border-box;
	width: 100%;
	position: relative;
	min-height: 100vh;
	padding-bottom: 80px;
	.layout-top {
		height: 48px;
		background-color: #222631;
		display: flex;
		justify-content: center;
	}
}
