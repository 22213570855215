.trading-targets {


	height: 320px;
	border-radius: 8px;
	background-color: #FFFFFF;
	box-shadow: 0 2px 4px 0 #3965981f;
	padding: 16px 24px;
}

.title {

	color: #333333;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 24px;

	& > span {

		font-size: 12px;
		margin-left: 12px;
		font-weight: 400;
	}
}

.content {

	display: flex;
	justify-content: space-between;

	& > div {

		width: 858px;
		height: 270px;
	}

	:global {

		::-webkit-scrollbar {
			width: 6px;
			height: 5px;
		}
		
		::-webkit-scrollbar-track {
			background: #fff;
			border-radius: 19px;
		}
		
		::-webkit-scrollbar-thumb {
			background: #D7E1F7;
			border-radius: 19px;
		}
	}

	& > div:nth-of-type(1) {

		& > div:nth-of-type(1) {

			margin-bottom: 10px;
			font-size: 14px;
		}

		& > div:nth-of-type(2) {

			:global {

				textarea:where(.css-dev-only-do-not-override-17a39f8).ac-input {

					max-height: 220px;
					border-radius: 8px;
				}

				::-webkit-scrollbar {
					width: 6px;
					height: 5px;
				}
				
				::-webkit-scrollbar-track {
					background: #fff;
					border-radius: 19px;
				}
				
				::-webkit-scrollbar-thumb {
					background: #D7E1F7;
					border-radius: 19px;
				}

				:where(.css-dev-only-do-not-override-17a39f8).ac-input-textarea-affix-wrapper.ac-input-affix-wrapper {

					border-radius: 8px;
					overflow: hidden;
				}

				.ac-input-suffix {

					position: relative;

					& > span {

						position: absolute;
						bottom: 0px;
						right: 15px;
					}
				}
			}
		}
	}

	& > div:nth-of-type(2) {

		overflow-y: scroll;
		overflow-x: hidden;
	}
}

.chosen-item {

	& > div:nth-of-type(1) {

		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 186px;
		margin-bottom: 14px;

		& > span {

			margin-right: 21px;
			font-size: 14px;
		}

		& > button {

			display: flex;
			justify-content: center;
			align-items: center;
			border-color: #455CDC;

			& > img {

				width: 16px;
				height: 16px;
				margin-right: 6px;
			}

			& > span {

				color: #455CDC;
			}
		}
	}

	& > div:nth-of-type(2) {

		display: flex;
		flex-wrap: wrap;

		& > div {

			width: 204px;
			height: 41px;
			background-color: #F7F6F9;
			border-radius: 8px;
			margin-right: 6px;
			margin-bottom: 12px;
			text-align: center;
			line-height: 41px;
			cursor: pointer;
			color: #727C94;
		}

		& > div:hover {

			box-shadow:  0 2px 4px 0 #39659840;
			transition: .5s;

			& > img {

				opacity: 1;
				transition: .5s;
			}
		}
	}
}

.item {

	position: relative;

	& > img {

		position: absolute;
		right: -2px;
		top: -8px;
		width: 16px;
		height: 16px;
		opacity: 0;
	}
}