.return-curve-container {
	:global {
		.ac-modal {
			width: 700px !important;
			.ac-modal-content {
				width: 600px;
				height: 350px;
				.ac-modal-title {
					color: #333333;
					font-family: "PingFang SC";
					font-size: 16px;
					font-weight: 500;
				}
				.ac-modal-body {
					> div:first-child {
						width: 600px;
						height: 350px;
						font-size: 20px;
						.spin-outside {
							text-align: center;
							margin-top: 115px;
						}
					}
				}
			}
		}
	}
}
