.fund-factor {

	margin-top: 18px;
	width: 1800px;

	& > div:nth-of-type(1) {

		display: flex;
		justify-content: space-between;
	}

	& > div:nth-of-type(2) {

		margin-top: 18px;
	}
}