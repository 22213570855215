.performance-container {
	width: 323px;
	height: 222px;
	padding: 18px;
	border-radius: 8px;
	background: #f6f6f9;
	box-sizing: border-box;
	.performance-item {
		display: flex;
		align-items: end;
		margin-bottom: 36px;
		.item-text {
			width: 225px;
			color: #727c94;
			font-size: 14px;
			font-weight: 400;
			font-family: "PingFang SC";
			text-align: right;
			margin-right: 18px;
		}
		.item-value {
			width: 81px;
			color: #333333;
			font-size: 16px;
			font-weight: 500;
			text-align: left;
		}
	}

}