.navigate-header {
	width: 1800px;
	height: 87px;
	display: flex;
	align-items: center;
	justify-content: start;

	& > img {
		width: 24px;
		height: 19px;
		margin-right: 8px;
		cursor: pointer;
	}
	& > span {
		color: #727c94;
 		font-size: 24px;
 		font-weight: 500;
 		font-family: "PingFang SC";
	}
	& > span:last-child {
		color: #333333;
	}
	& > span:nth-child(2) {
		cursor: pointer;
	}
}