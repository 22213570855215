.login {

	width: 100%;
    height: 100vh;
	position: relative;
	background: url("src/shared/assets/png/loginBg.png") no-repeat;
	background-size: cover;
	overflow:hidden;

	.logo {
		width: 100%;
		top: 59px;
		left: 119px;
		position: absolute;

		& > div:nth-of-type(1) {
			width: 50px;
			height: 35px;
			background: url("src/shared/assets/png/loginTitle.png") no-repeat;
			background-size: cover;
			float: left;
		}

		& > div:nth-of-type(2) {
			width: 2px;
			height: 35px;
			background: #455CDC;
			margin-left: 24px;
			margin-right: 24px;
			float: left;
		}

		& > div:nth-of-type(3) {
			width: 290px;
			height: 24px;
			margin-top: 6px;
			background: url("src/shared/assets/png/loginSubtitle.png") no-repeat;
			background-size: cover;
			float: left;
		}
	}

	.login-content {

		width: 80vw;
		min-width: 1400px;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		position: relative;

		.icon {
			width: 779px;
			height: 485px;
			position: absolute;
			top: calc(50% - 240px);
		}

		.content {
			width: 365px;
			height: 367px;
			padding: 32px;
			border-radius: 8px;
			opacity: 1;
			box-shadow: 0 16px 32px 0 #a6bfdc40;
			background: #ffffff;
			position: absolute;
			top: calc(50% - 215px);
			right: 122px;
		}

		.userInformation-content {
			width: 407px;
			height: 553px;
			padding: 32px;
			border-radius: 8px;
			opacity: 1;
			box-shadow: 0 16px 32px 0 #a6bfdc40;
			background: #ffffff;
			position: absolute;
			top: calc(50% - 300px);
			right: 122px;
		}
	}
}

.summary {
	height: 17px;
	margin-top: 16px;
	opacity: 1;
	font-size: 12px;
	font-weight: 400;
	font-family: "PingFang SC";
	text-align: center;

	.summary-left {

		color: #666666;
	}

	.summary-right {

		color: #455CDC;
		cursor: pointer;
	}
}

.header {
	height: 34px;
	margin-bottom: 24px;
	opacity: 1;
	color: #333333;
	font-size: 24px;
	font-weight: 500;
	font-family: "PingFang SC";
	text-align: left;
}

.user-information {

	:global {
		.ac-input {

			background-color: #ffffff;
			color: black;
			border-radius: 8px;
			height: 41px;
			border-color: #d9d9d9;
		}

		.ac-input:focus {

			border-color: #455CDC;
			box-shadow: none;
		}

		.ac-input-password {

			height: 41px;
			padding: 2px 11px;
			background-color: #ffffff;
			border-radius: 8px;
			border-color: #d9d9d9;

			& > input {

				margin: 0;
				height: 100%;
				background-color: #ffffff;
				border-radius: 0;
			}
		}

		.ac-input-password-icon {

			cursor: pointer;
		}

		.ac-input-affix-wrapper-focused {

			border-color: #455CDC !important;
			box-shadow: none;
		}

		.ac-input-group-addon {

			color: #455CDC;
			background: #ffffff;
			border-color: #d9d9d9;
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
		}

		.ac-form-item {

			height: 80px;
		}

		.ac-form-item-control {

			min-width: 100%;
			position: relative;
		}

		.ac-form-item-explain {

			margin-top: 8px;
			color: #f83464;
			font-size: 10px;
		}

		.ac-input-status-error:not(.ac-input-disabled).ac-input {
			border-color: #f83464;
		}

		.ac-input-affix-wrapper-status-error:not(.ac-input-affix-wrapper-disabled).ac-input-affix-wrapper {
			border-color: #f83464;
		}
	}
}

.finish {

	height: 49px;
	margin-top: 20px;
	padding: 0;
	border-radius: 4px;
	min-width: 114px;

	:global {
		.ac-btn {

			width: 100%;
			background-size: cover;
			border: none;
			color: #d3f2fcff;
			font-size: 16px;
		}

		.ac-btn:hover {

			color: #fff;
			opacity: 1;
			border: none;
		}

		.ac-btn:focus {

			color: #d3f2fcff;
		}
	}

	.application-btn {

		background:  radial-gradient(87.6% 100% at 46.7% 0%, #61dafb 0%, #455cdc 100%);;
		border-radius: 4px;
		font-weight: 500;
		color: #FFFFFF;
		border: none;
		width: 100%;
		height: 100%;

		& > span {

			height: 25px;
			font-size: 18px;
		}
	}
}

.button {

	height: 49px;
	margin-top: 42px;
	padding: 0;
	border-radius: 4px;
	min-width: 114px;

	:global {
		.ac-btn {

			width: 100%;
			background-size: cover;
			border: none;
			color: #d3f2fcff;
			font-size: 16px;
		}

		.ac-btn:hover {

			color: #fff;
			opacity: 1;
			border: none;
		}

		.ac-btn:focus {

			color: #d3f2fcff;
		}
	}

	.application-btn {

		background:  radial-gradient(87.6% 100% at 46.7% 0%, #61dafb 0%, #455cdc 100%);;
		border-radius: 4px;
		font-weight: 500;
		color: #FFFFFF;
		border: none;
		width: 100%;
		height: 100%;

		& > span {

			height: 25px;
			font-size: 18px;
		}
	}
}

.forgetPassword{

	height: 14px;
	font-size: 10px;
	position: relative;
	float: right;
	color: #666666;
	cursor: pointer;
}

.reacquire {
	color: #798197;
}

.send {
	height: 40px;
	line-height: 40px;
	cursor: pointer;
}

.label {
	height: 20px;
	margin-bottom: 8px;
	opacity: 1;
	color: #000000;
	font-size: 14px;
	font-weight: 500;
	font-family: "PingFang SC";
	text-align: left;

	.labelContent {
		height: 25px;
		line-height: 25px;
	}
}