.settled{
	width: 376px;
	height: 69px;
	margin-right: 12px;
	padding: 12px;
	border-radius: 8px;
	opacity: 1;
	background: #eceffb;
	float: left;
}

.unsettled {
	width: 376px;
	height: 69px;
	margin-right: 12px;
	padding: 12px;
	border-radius: 8px;
	opacity: 1;
	background: #eceffb;
	float: left;
}

.ratio {
	width: 120px;
	height: 48px;
	padding: 22px 12px 22px 12px;
	border-radius: 8px;
	opacity: 1;
	color: #333333;
	background: #eceffb;
	text-align: left;
	font-family: "PingFang SC";
	float: left;

	& > div:nth-of-type(1) {
		height: 17px;
		margin-bottom: 12px;
		font-size: 12px;
		font-weight: 400;
		text-align: left;
	}

	& > div:nth-of-type(2) {
		height: 20px;
		font-size: 14px;
		font-weight: 500;
		float: left;
	}

	& > div:nth-of-type(3) {
		height: 20px;
		margin-left: 6px;
		font-size: 14px;
		font-weight: 400;
		float: left;
	}
}

.card_title {
	width: 61px;
	height: 19px;
	border-radius: 4px;
	opacity: 1;
	background: #455cdc33;
	opacity: 1;
	color: #455cdc;
	line-height: 19px;
	font-size: 12px;
	font-weight: 400;
	font-family: "PingFang SC";
	text-align: center;
}

.profit {

	.profit_text {
		width: 220px;
		height: 17px;
		margin-top: 6px;
		opacity: 1;
		color: #333333;
		font-size: 12px;
		font-weight: 400;
		font-family: "PingFang SC";
		text-align: right;
		float: left;
	}

	.profit_number {
		height: 20px;
		margin-top: 4px;
		margin-left: 12px;
		opacity: 1;
		color: #333333;
		font-weight: 500;
		text-align: left;
		font-size: 14px;
		font-family: "PingFang SC";
		float: left;
	}

	.USDT {
		height: 20px;
		margin-top: 6px;
		margin-left: 4px;
		opacity: 1;
		color: #333333;
		font-weight: 400;
		line-height: 20px;
		text-align: left;
		font-size: 10px;
		font-family: "PingFang SC";
		float: left;
	}
}