.identity {
	height: 24px;
	opacity: 1;
	color: #ffffff;
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
	font-family: "PingFang SC";
	text-align: left;
	position: absolute;
	top: 12px;
	right: 117px;

	.identity_text {
		height: 20px;
		margin-top: 1px;
		margin-right: 4px;
		opacity: 1;
		color: #ffffff;
		font-size: 14px;
		font-weight: 400;
		font-family: "PingFang SC";
		text-align: left;
		line-height: 20px;
		float: left;
	}

	.identity_icon {
		width: 12px;
		height: 12px;
		margin-top: 5px;
		background: url("src/shared/assets/png/switchIdentities.png") no-repeat;
		background-size: cover;
		float: left;
	}
}