.benefit-riskFactors {

	margin-left: 18px;
	flex: 1;
	width: 1150px;
	height: 400px;
	background-color: #FFFFFF;
	border-radius: 8px;
	box-shadow: 0 2px 4px 0 #3965981f;
	padding: 16px 24px;
	display: flex;
	flex-direction: column;
	align-items: end;
	& > div {
		width: 100%;
	}
}

.title {

	color: #333333;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 24px;
}

.content {

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	& > div {

		width: 48%;
		font-size: 14px;
		margin-bottom: 16px;

		& > div:nth-of-type(1) {

			margin-bottom: 8px;
		}

		:global {

			.ac-input {

				width: 100%;
				height: 33px;
			}

			:where(.css-dev-only-do-not-override-17a39f8).ac-input-affix-wrapper {

				height: 41px;
			}
		}
	}
}

.bottom {

	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 522px;

	& > div > span {

		margin-left: 15px;
		font-family: "JosefinSansRegular";
	}

	& > div > button {

		color: #455CDC;
		font-size: 16px;
		border-radius: 8px;
		background-color: #EDEFFC;
		border-color: #EDEFFC;
	}

	:global {

		:where(.css-dev-only-do-not-override-17a39f8).ac-btn {

			height: 30px;
			padding: 0 15px;

		}
	}
}