.steps {
	width: 1800px;
	:global {
		.ac-steps {
			height: 74px;
		}
		.ac-steps .ac-steps-item:first-child {
			margin-left: 350px;
		}
		.ac-steps .ac-steps-item:last-child {
			margin-right: 350px;
		}
		.ac-steps .ac-steps-item .ac-steps-item-container {
			height: 74px;
			display: flex;
			align-items: center;
			padding: 0 20px;
		}
		.ac-steps .ac-steps-item .ac-steps-item-container .ac-steps-item-title {
			color: #727c94;
			font-size: 16px;
			font-weight: 500;
			font-family: "PingFang SC";
		}
		.ac-steps .ac-steps-item-finish .ac-steps-item-container {
			margin-left: -20px;
		}
		.ac-steps .ac-steps-item-icon {
			transition: none;
		}
		.ac-steps .ac-steps-item-finish .ac-steps-item-icon {
			width: 64px;
			height: 64px;
		}
		.ac-steps .ac-steps-item-process .ac-steps-item-icon {
			width: 32px;
			height: 32px;
			border-radius: 4px;
			background: #455cdc;
			box-shadow: 0 8px 16px 0 #455cdc3d;
			margin-right: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.ac-steps .ac-steps-item-process .ac-steps-item-icon .ac-steps-icon {
			color: #ffffff;
			font-size: 16px;
			font-weight: 500;
			font-family: "PingFang SC";
		}
		.ac-steps .ac-steps-item-wait .ac-steps-item-icon {
			width: 32px;
			height: 32px;
			border-radius: 4px;
			background: #ffffff;
			box-shadow: 0 8px 16px 0 #575f8e3d;
			margin-right: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.ac-steps .ac-steps-item-wait .ac-steps-item-icon .ac-steps-icon {
			color: #727c94;
			font-size: 16px;
			font-weight: 500;
			font-family: "PingFang SC";
		}
	}
	.steps-icon {
		width: 64px;
		height: 64px;
		margin-top: 6px;
	}
}