.strategy-container {
	width: 1800px;
	box-sizing: border-box;
	height: 650px;
	padding: 18px;
	margin-top: 15px;
	border-radius: 8px;
	background: #ffffff;
	box-shadow: 0 2px 4px 0 #3965981f;
	:global {
		.ac-table-thead > tr > th {
			color: #a0a9c0;
			font-size: 14px;
			font-weight: 400;
			font-family: "PingFang SC";
			background-color: #fff;
			border-bottom: none;
		}
		.ac-table-wrapper .ac-table-tbody > tr > td {
			height: 52px;
		}
		.ac-table-thead > tr > th::before {
			width: 0 !important;
		}
		.ac-table-tbody>tr>td {
			color: #333333;
			font-size: 14px;
			font-weight: 400;
			font-family: "PingFang SC";			
		}
		.ac-table-wrapper table tr td.ac-table-selection-column {
			padding-inline-end: 0;
		}
		.ac-table-wrapper table tr th.ac-table-selection-column {
			padding-inline-end: 0;
		}
		.ac-checkbox-wrapper,
		.ac-checkbox,
		.ac-checkbox-inner {
			width: 16px;
			height: 16px;
		}
		.ac-checkbox {
			margin-bottom: 6px;
		}
		.ac-checkbox .ac-checkbox-inner:after {
			inset-inline-start: 24.5%;
			width: 5px;
			height: 8px;
		}
		::-webkit-scrollbar {
			height: 4px;
			background: #fff;
			border-radius: 2px;
		  }
		::-webkit-scrollbar-track,
		::-webkit-scrollbar-thumb {
			height: 4px;
			background: #455CDC33;
			border-radius: 2px;
		}
		::-webkit-scrollbar-track {
			background: rgba(255, 255, 255, 0.03);
		}
		.ac-pagination .ac-pagination-item {
			width: 24px;
			height: 24px;
			border-radius: 2px;
			border: 1px solid #d9d9d9;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.ac-pagination .ac-pagination-item a {
			color: #000000d9;
			font-size: 14px;
			font-weight: 400;
			font-family: "Roboto";
		}
		.ac-pagination .ac-pagination-item-active {
			width: 24px;
			height: 24px;
			border-radius: 2px;
			border: 1px solid #455cdc;
		}
		.ac-pagination .ac-pagination-item-active a {
			color: #455CDC;
			font-size: 14px;
			font-weight: 400;
			font-family: "Roboto";
		}
		.ac-pagination .ac-pagination-next button,
		.ac-pagination .ac-pagination-prev button {
			width: 24px;
			height: 24px;
			border-radius: 2px;
			border: 1px solid #d9d9d9;
		}

		.ac-pagination .ac-pagination-disabled button {
			background-color: #D9D9D933;
			width: 24px;
			height: 24px;
			border-radius: 2px;
			border: 1px solid #d9d9d9;
		}
		.ac-pagination .ac-pagination-disabled .anticon {
			& > svg {
				width: 14px;
				height: 18px;
				color: #D9D9D9;
			}
		}
		.ac-pagination .ac-pagination-next button .anticon,
		.ac-pagination .ac-pagination-prev button .anticon {
			& > svg {
				width: 14px;
				height: 18px;
				color: #000000d9;
			}
		}
		.ac-pagination .ac-pagination-prev .ac-pagination-item-link {
			transition: none;
		}
	}
	.strategy-header {
		height: 34px;
		color: #333333;
 		font-size: 18px;
 		font-weight: 500;
 		font-family: "PingFang SC";
	}
	.strategyName {
		width: 120px;
	}
	.strategyNick {
		width: 105px;
	}
	.pair {
		width: 80px;
	}
	.interval,
	.leverage {
		width: 40px;
	}
	.initialCapital {
		width: 170px;
	}
	.positionType {
		width: 100px;
	}
	.timeRange {
		width: 180px;
	}
	.tradeNumber {
		width: 90px;
	}
	.cumulativeReturn {
		width: 130px;
	}
	.maxDrawDownRate {
		width: 110px;
	}
	.averageProfitLossRatio {
		width: 70px;
	}
	.annualizedReturn {
		width: 65px;
	}
}