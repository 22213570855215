.steps-buttons {
	margin-top: 24px;
	:global {
		.ac-btn-primary {
			width: 125px;
			height: 46px;
			border-radius: 4px;
			background: #455cdc;
			color: #ffffff;
			font-size: 16px;
			font-weight: 500;
			font-family: "PingFang SC";
		}
		.ac-btn-default {
			width: 125px;
			height: 46px;
			border-radius: 4px;
			border: 1px solid #455cdc;
			color: #455cdc;
			font-size: 16px;
			font-weight: 500;
			font-family: "PingFang SC";
		}
	}
}