.myfund-container {
	background-image: url("@shared/assets/png/myfund_bg.png");
	background-size: 100% 140px;
	background-repeat: no-repeat;
	border-top: 1px solid #222631;
	box-sizing: border-box;
	::-webkit-scrollbar {
		height: 7px;
	}

	::-webkit-scrollbar-track {
		background: #f1f3f4;
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb {
		background: #ccccccb0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #c4c4c4;
	}
}
