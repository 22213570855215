.chart-container {
	width: 810px;
	height: 250px;
	position: relative;
	.chart-header {
		width: 100%;
		text-align: center;
		color: #333333;
		font-size: 14px;
		font-weight: 400;
		font-family: "PingFang SC";
		margin-bottom: 8px;
	}
	.chart {
		width: 810px;
		height: 222px;
	}
	.empty-img {
		width: 182px;
		height: 99px;
		position: absolute;
		top: 60px;
		left: 315px;
	}
}
