.myfund-table-container {
	width: 1200px;
	margin: 8px auto 20px;
	border-radius: 8px;
	opacity: 1;
	background: #ffffff;
	box-shadow: 0 2px 4px 0 #3965981f;
	:global {
		table {
			.ac-table-thead {
				.ac-table-cell {
					background-color: #ffffff;
					color: #a0a9c0;
					font-size: 14px;
				}
			}
			.ac-table-tbody {
				.ac-table-row {
					color: #333333;
					font-size: 16px;
					font-weight: 400;
					padding: 0 16px;
					height: 82px;
					.safety-net {
						color: #455cdc;
						font-size: 10px;
						font-weight: 500;
						background: #455cdc1a;
						width: 148px;
						height: 18px;
						line-height: 18px;
						border-radius: 2px;
						text-align: center;
						margin: auto;
					}
					.medal-icon {
						display: flex;
						align-items: center;
						margin: 10px 0 0 5px;
						img {
							height: 24px;
							width: 24px;
						}
						.fundName {
							margin-left: 8px;
						}
					}
					.profit-detail {
						img {
							height: 28px;
							width: 28px;
						}
					}
					.mini-chart {
						display: flex;
						align-items: center;
						justify-content: center;
						img {
							height: 16px;
							width: 16px;
							margin-left: 5px;
						}
					}
				}
				.ac-table-cell-row-hover {
					background-color: #edeffc !important;
				}
			}
		}
		.ac-pagination {
			.ac-pagination-item {
				border-radius: 2px;
				border: 1px solid #d9d9d9;
				> a {
					color: #000000;
				}
			}
			.ac-pagination-item-active {
				border: 1px solid #455cdc;
				> a {
					color: #455cdc;
				}
			}
		}
	}
}
