.detailTable {
	:global {
		.ac-table-wrapper {

			.ac-spin-container {

				.ac-table {

					.ac-table-content {

						.ac-table-thead {
							background: #ffffff;
							height: 20px;
							opacity: 1;

							.ac-table-cell {
								height: 20px;
								padding: 0;
								opacity: 1;
								background: #ffffff;
								border-bottom: 0px;
								color: #a0a9c0;
								font-size: 14px;
								font-weight: 400;
								font-family: "PingFang SC";
								text-align: left;
							}

							.ac-table-cell:nth-of-type(3),
							.ac-table-cell:nth-of-type(4),
							.ac-table-cell:nth-of-type(5) {
								text-align: right;
							}
						}

						.ac-table-tbody {

							.ac-table-cell {
								height: 52px;
								padding: 16px 0;
								opacity: 1;
								border-bottom: 1px solid #f5f6f9;
								color: #333333;
								font-size: 14px;
								font-weight: 400;
								font-family: "PingFang SC";
								text-align: left;
							}
						}

						.ac-table-tbody > tr > td:nth-of-type(3),
						.ac-table-tbody > tr > td:nth-of-type(4),
						.ac-table-tbody > tr > td:nth-of-type(5) {
							text-align: right;
						}
					}
				}
			}
		}
	}
}