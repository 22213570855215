.page-bottom {
	box-sizing: border-box;
	width: 100%;
	height: 60px;
	margin-top: 30px;
	position: absolute;
	bottom: 0;
	background-color: #2D3347;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 120px;
	color: #ffffff;
	font-size: 12px;
	font-weight: 400;
	font-family: "PingFang SC";

	.bottom-right {
		display: flex;
		align-items: center;
		& > a > img {
			margin-left: 24px;
			width: 32px;
			height: 32px;
		}
		& > img{
			margin-left: 0;
			margin-right: 4px;
			width: 12px;
			height: 12px;
		}
	}
}