.lang {
	width: 32px;
	height: 32px;
	position: absolute;
	top: 8px;
	right: 5px;

	.en {
		width: 32px;
		height: 32px;
		background: url("src/shared/assets/png/en.png") no-repeat;
		background-size: cover;
	}

	.zh_CN {
		width: 32px;
		height: 32px;
		background: url("src/shared/assets/png/zh_CN.png") no-repeat;
		background-size: cover;
	}

	.zh_HK {
		width: 32px;
		height: 32px;
		background: url("src/shared/assets/png/zh_HK.png") no-repeat;
		background-size: cover;
	}
}

.languageDrop {

	:global {
		.ac-dropdown-menu {
			margin-top: 16px;
			background: #222631;
			border-radius: 8px;
			padding: 12px;
			border: 1px solid #455CDC;
		}

		.ac-dropdown-menu-title-content {
			height: 22px;
			opacity: 1;
			color: #ffffff;
			font-size: 16px;
			font-weight: 500;
			font-family: "PingFang SC";
			text-align: left;
		}
	}
}
