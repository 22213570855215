.basic-information {

	width: 600px;
	height: 400px;
	border-radius: 8px;
	background-color: #FFFFFF;
	box-shadow: 0 2px 4px 0 #3965981f;
	padding: 16px;
}

.title {

	color: #333333;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 24px;
}

.content {

	font-size: 14px;
	font-weight: 400;

	& > div:nth-of-type(1) {

		position: relative;

		& > div:nth-of-type(1) {

			margin-bottom: 8px;
		}

		:global {

			:where(.css-dev-only-do-not-override-17a39f8).ac-input-affix-wrapper {

				height: 43px;
			}
		}
	}

	& > div:nth-of-type(2) {

		margin-top: 16px;

		& > div:nth-of-type(1) {

			margin-bottom: 8px;
		}

		& > div:nth-of-type(2) {

			display: flex;
			justify-content: space-between;
			align-items: center;

			:global {

				.ac-picker {

					width: 250px;
					height: 41px;
					font-size: 12px;
				}
			}
		}
	}

	& > div:nth-of-type(3) {

		margin-top: 18px;

		& > div:nth-of-type(2) {

			margin-top: 8px;
		}

		:global {

			.ac-radio-wrapper {

				& > span {

					font-size: 14px;
					line-height: 14px;
				}
			}
		}
	}

	& > div:nth-of-type(4) {

		margin-top: 18px;
		
		& > div:nth-of-type(1) {

			display: flex;
			align-items: center;

			& > img {

				margin-left: 6px;
			}
		}

		& > div:nth-of-type(2) {

			margin-top: 10px;

			:global {

				.ac-select-selector {

					width: 600px;
					height: 41px;

					& > span {

						height: 41px;
						font-size: 14px;
						line-height: 41px;

						& > input {

							height: 100%;
						}
					}
				}
			}
		}
	}

	& > div:nth-of-type(5) {

		margin-top: 10px;
	}
}

.name-warning {

	position: absolute;
	bottom: -20;
	color: #F83464;
}