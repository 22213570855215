.content {

	width: 752px;
	height: 728px;

	& > div:nth-of-type(1) {

		font-size: 16px;
	}

	& > div:nth-of-type(2) {

		width: 100%;
		height: 1px;
		background: #F5F5F5;
		margin-top: 16px;
		margin-bottom: 22px;
	}
	
	& > div:nth-of-type(3) {

		display: flex;
		flex-wrap: wrap;
		width: 760px;
		justify-content: start;
		max-height: 660px;
		overflow-y: scroll;

		& > div:nth-of-type(5n) {

			margin-right: 0;
		}
	}

	:global {

		::-webkit-scrollbar {
			width: 6px;
			height: 5px;
		}
		
		::-webkit-scrollbar-track {
			background: #fff;
			border-radius: 19px;
		}
		
		::-webkit-scrollbar-thumb {
			background: #D7E1F7;
			border-radius: 19px;
		}
	}
}

.item {

	width: 140px;
	height: 41px;
	border-radius: 8px;
	background: #F7F6F9;
	margin-right: 13px;
	margin-bottom: 12px;
	text-align: center;
	line-height: 41px;
	position: relative;
	cursor: pointer;

	& > img {

		position: absolute;
		right: 0;
		bottom: 0;
		width: 30px;
		height: 30px;
	}
}

.item:hover {

	box-shadow:  0 2px 4px 0 #39659840;
	transition: .5s;
}