.myfund-header-container {
	margin: auto;
	width: 1200px;
	margin-top: 32px;
	.header-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		> div:first-child {
			color: #ffffff;
			font-size: 24px;
			font-weight: 600;
		}
		> div:last-child {
			width: 125px;
			height: 47px;
			line-height: 46px;
			border-radius: 4px;
			background: #455cdc;
			color: #ffffff;
			font-size: 16px;
			font-weight: 500;
			text-align: center;
			cursor: pointer;
		}
	}
	.header-cards {
		margin-top: 32px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.card {
			display: flex;
			align-items: center;
			width: 392px;
			height: 93px;
			border-radius: 8px;
			background: linear-gradient(180deg, #487bff 0%, #455cdc 100%);
			box-shadow: 0 2px 4px 0 #3965981f;
			> div:first-child {
				margin-left: 34px;
				img {
					width: 43px;
					height: 43px;
				}
			}
			> div:last-child {
				margin-left: 32px;
				color: #ffffff;
				font-weight: 500;
				> div:first-child {
					font-size: 18px;
				}
				> div:last-child {
					font-size: 24px;
					font-family: JosefinSansRegular;
					margin-top: 8px;
					span {
						font-size: 14px;
						font-weight: 400;
					}
				}
			}
		}
	}
}
