.modal-container {

	margin-left: -600px;

	:global {
		.ac-modal {
			width: 1000px;
		}

		.ac-modal-content {
			width: 1000px;
			height: 100%;
			padding: 16px;
		}
	}
}

.icon{
	cursor: pointer;
}

.text {
	height: 93px;
}

.table {
	margin-top: 24px;

	:global {
		.ac-tabs-nav {
			margin-bottom: 12px;
			border-bottom: 1px solid white;

			.ac-tabs-nav-list {

				.ac-tabs-tab {

					width: 85px;
					height: 33px;
					margin-left: 0;
					margin-right: 12px;
					border-radius: 8px;
					font-size: 15px;
					background: #455cdc1a;

					&-btn {
						width: 100%;
						color: #798197;
						opacity: 1;
						line-height: 33px;
						font-size: 12px;
						font-weight: 400;
						font-family: "PingFang SC";
						text-align: center;
					}

					&.ac-tabs-tab-active {
						background: #455CDC;
					}

					&.ac-tabs-tab-active > .ac-tabs-tab-btn {
						color: #ffffff;
					}
				}
			}
		}

		.ac-tabs-ink-bar,
		.ac-tabs-ink-bar-animated {
			display: none;
		}
	}
}

