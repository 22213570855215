.form-container {
	width: 525px;
	height: 251px;
	:global {
		.ac-input,
		.ac-select {
			width: 525px;
			height: 43px;
			border-radius: 8px;
			border: 1px solid #a0a9c0;
			color: #333333;
			font-size: 14px;
			font-weight: 400;
			font-family: "PingFang SC";
			margin-bottom: 19px;
		}
		.ac-select-selector {
			width: 100% !important;
			height: 100% !important;
			border-radius: 8px !important;
			border: none !important;
			color: #33333399;
			font-size: 14px;
			font-weight: 400;
			font-family: "PingFang SC";
			display: flex;
			align-items: center;
		}
		.ac-select-selector .ac-select-selection-search {
			width: 525px !important;
			height: 43px !important;
			& > input {
				width: 100%;
				height: 100% !important;
			}
		}
		.ac-select-status-error {
			border: 1px solid #f83464;
		}
		.ac-form-item .ac-form-item-label {
			height: 28px;
		}
		.ac-form-item .ac-form-item-label label {
			color: #333333;
			font-size: 14px;
			font-weight: 400;
			font-family: "PingFang SC";
		}
		.ac-form-item {
			margin-bottom: 19px;
		}
		.ac-form-item:last-child {
			margin-bottom: 0;
		}
		.ac-form-item:first-child .ac-input {
			border: 1px solid #ffffff;
			background: #a0a9c01a;
		}

		.ac-picker {
			width: 243px;
			height: 43px;
			border-radius: 8px;
			border: 1px solid #a0a9c0;
			font-size: 14px;
		}
		.ac-picker .ac-picker-input >input {
			color: #33333399;
			font-size: 14px;
			font-weight: 400;
			font-family: "PingFang SC";
			text-align: center;
		}
		.ac-form-item .ac-form-item-explain-error {
			color: #f83464;
			font-size: 10px;
			font-weight: 400;
			font-family: "PingFang SC";			
		}
	}
	.link-word {
		color: #a0a9c0;
		font-size: 14px;
		font-weight: 400;
		font-family: "PingFang SC";
		margin: 0 12px;
	}
	.account-label {
		margin-bottom: 8px;
	}
	.select-popup {
		height: 36px;
		background-color: red;
	}
	.date-range {
		display: flex;
		align-items: center;
		position: relative;

		.end-time {
			margin-top: 27px;
		}
		.start-img {
			width: 13px;
			height: 13px;
			position: absolute;
			left: 38px;
			top: 42px;
		}
		.end-img {
			width: 13px;
			height: 13px;
			position: absolute;
			top: 42px;
			left: 318px;
		}
	}
}